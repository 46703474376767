import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import { useState, useEffect } from 'react';
import Home from './home';
import Tags from './tags';
import Months from './months';
import Article from './article';
import ArticleEdit from './articleEdit';
import Login from './login';
import {
  shouldGetContextFromApi,
  checkCanEdit,
  checkDomain,
  host,
  getPersistentState,
  setPersistentState,
  clearPersistentState,
} from './utility';


function App() {
  const test = window.location.href.indexOf('test/') > -1;

  const storedState = getPersistentState();
  console.log('App | get AppState:', storedState ? JSON.stringify(storedState) : undefined);
  const initialState = storedState ||
    { auth:{key:''}, context: { siteIndex: null, fullArticles: {}} };
  const [state, setState] = useState(initialState);
  // const [auth, setAuth] = useState('');
  let { auth, context } = state;
  console.log('App | state:',
    state ? JSON.stringify(state) : undefined,
    'auth:', auth, 'shouldGetContextFromApi:', shouldGetContextFromApi,
  );

  const [tab, setTab] = useState(null);

  // Store variables embedded in content so we can prompt re-render by recreating object
  // when making changes (instead of upating properties)
  // Reference change will prompt rerender.
  let eligible = checkDomain(context?.siteIndex);
  const [options, setOptions] = useState({
    eligible,
    eligClass: eligible ? 'eligible' : '',
    canEdit: checkCanEdit(auth),
  });

  function refreshOptions(eligible = checkDomain(context?.siteIndex)) {
    setOptions({
      eligible,
      eligClass: eligible ? 'eligible' : '',
      canEdit: checkCanEdit(auth),
    });
    console.log(`App | refreshOptions | options: ${JSON.stringify(options)}`);
  }

  function setAuth(authIn) {
    state.auth = auth = authIn;
    // console.log('App.setAuth | auth:', auth);
    clearPersistentState();
    getContextFromServer(auth);
    // refreshOptions();
  }

  function setContext(contextIn) {
    state.context = context = contextIn;
    // console.log('App.setContext | context:', context);
    setPersistentState(state);
    setState(state);
    // refreshOptions();
  }

  function getContext() { return context };

  function getContextFromServer(auth) {
    state.auth = auth;
    const body = {
      action: test ? 'get-test-index' : 'get-index',
      token: auth?.key ? 'Basic ' + Buffer.from(auth.key).toString('base64') : undefined,
    };
    const bodyStr = JSON.stringify(body);
    // console.log('getContextFromServer | auth:', auth, 'body:', body, 'bodyStr:', bodyStr);

    fetch(host, {
      method: 'POST',
      headers: {
        Accepts: "application/json",
        "Content-Type": "application/json",
      },
      body: bodyStr,
    })
    .then(response => {
      // console.log('getContextFromServer | response:', response);
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      // console.log('getContextFromServer | data:', data);
      state.context.siteIndex = data;
      state.context.fullArticles = {};
      setPersistentState(state);
    })
    .catch((error) => {
      console.error(error);
    });
  }

  let contextObtained = !!context?.siteIndex?.articles;
  if (!contextObtained) {
    if (!shouldGetContextFromApi) {
      // console.log('App | getting context | from path...');
      window.localStorage.clear();
      const loadStart = Date.now();
      // import('./data/index.json'
      const fileName = `../data/${test ? 'test' : 'index'}.json`;
      fetch(
        fileName,
        {
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        },
      ).then(response => {
        console.log('getContextFromServer | duration:', Date.now() - loadStart);
        if (response.ok) {
          return response.json();
        }
      })
      .then(data => {
        if (data) {
          console.log('App | getting context | from path | data:', typeof data, data);
          // console.log('App | getting context | from path | state:', typeof state, state);
          // console.log('App | getting context | from path | initialState:', typeof initialState, initialState);
          if (typeof data === 'string') {
            data = JSON.parse(data);
          }
          state.test = test;
          state.context.siteIndex = data;
          state.context.fullArticles = {};

          setState(state);
          setPersistentState(state);
          contextObtained = true;

          const eligible = checkDomain(data, true);
          refreshOptions(eligible);
          
          console.log('App | eligiblility | 2 |', options, state?.context?.siteIndex?.k);
        } else {
          console.log('App | getting context | failed to load from path.');
        }
      });
    } else {
      console.log('App | getting context | from api...');
      getContextFromServer(auth);
    }
  }

  return (
    <Router>
      <div id="App" className={options.eligClass}>
        <header className="App-header">
          <h1>{context?.siteIndex?.siteTitle ?? 'Diary'}</h1>
          {contextObtained && <div className='nav'>
            <ul>
              <li key='home'>
                {tab === 'home' ? <span>Home</span> : <Link to={test ? "/test/" : "/"}>Home</Link>}
              </li>
              <li key='tags'>
              {tab === 'tags' ? <span>Tags</span> : <Link to={test ? "/test/tags/" : "/tags"}>Tags</Link>}
              </li>
              <li key='months'>
              {tab === 'months' ? <span>Months</span> : <Link to={test ? "/test/months/" : "/months"}>Months</Link>}
              </li>
              {options.canEdit && <li key='new'>
                <Link to={test ? "/test/edit/new" : "/edit/new"}>New</Link>
              </li>}
              {options.eligible && !options.canEdit && <li key='login'>
                <Link to={test ? "/test/login" : "/login"}>Login</Link>
              </li>}
              {options.eligible && options.canEdit && <li key='logout'>
                <Link to={test ? "/test/login" : "/login"}>Logout</Link>
              </li>}
            </ul>
          </div>}
          
          <Routes>
            <Route exact path='/' element={<Home getContext={getContext} auth={auth} setTab={setTab} />}></Route>
            <Route exact path="/tags" element={<Tags context={context} auth={auth} setTab={setTab} />}></Route>
            <Route exact path="/months" element={<Months context={context} auth={auth} setTab={setTab} />}></Route>
            <Route exact path="/tags/:tagId" element={<Tags context={context} auth={auth} setTab={setTab} />}></Route>
            <Route exact path="/months/:monthId" element={<Months context={context} auth={auth} setTab={setTab} />}></Route>
            <Route exact path="/article/:articleId" element={<Article getContext={getContext} auth={auth} setTab={setTab} setContext={setContext} />}></Route>
            <Route exact path="/edit/:articleId" element={<ArticleEdit getContext={getContext} auth={auth} setTab={setTab} setContext={setContext} />}></Route>
            <Route exact path="/edit/new" element={<ArticleEdit getContext={getContext} auth={auth} setTab={setTab} setContext={setContext} />}></Route>
            <Route exact path="/login" element={<Login auth={auth} getContext={getContext} setTab={setTab} setAuth={setAuth} setContext={setContext} />}></Route>
            <Route exact path='/test/' element={<Home test={true} getContext={getContext} auth={auth} setTab={setTab} />}></Route>
            <Route exact path="/test/tags" element={<Tags test={true} context={context} auth={auth} setTab={setTab} />}></Route>
            <Route exact path="/test/months" element={<Months test={true} context={context} auth={auth} setTab={setTab} />}></Route>
            <Route exact path="/test/tags/:tagId" element={<Tags test={true} context={context} auth={auth} setTab={setTab} />}></Route>
            <Route exact path="/test/months/:monthId" element={<Months test={true} context={context} auth={auth} setTab={setTab} />}></Route>
            <Route exact path="/test/article/:articleId" element={<Article test={true} getContext={getContext} auth={auth} setTab={setTab} setContext={setContext} />}></Route>
            <Route exact path="/test/edit/:articleId" element={<ArticleEdit test={true} getContext={getContext} auth={auth} setTab={setTab} setContext={setContext} />}></Route>
            <Route exact path="/test/edit/new" element={<ArticleEdit test={true} getContext={getContext} auth={auth} setTab={setTab} setContext={setContext} />}></Route>
            <Route exact path="/test/login" element={<Login test={true} getContext={getContext} auth={auth} setTab={setTab} setAuth={setAuth} setContext={setContext} />}></Route>
          </Routes>
        </header>
      </div>
    </Router>
  );
}

export default App;
