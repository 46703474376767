import React from "react";
import { Link, useParams } from "react-router-dom";
import { dateToDay, checkDisplayArticle, renderTags, collectMonthList } from './utility';

function Months(props) {
  const { test, auth, context, setTab } = props;
  const { siteIndex } = context;
  const { monthId } = useParams();

  if (setTab && typeof setTab === 'function') setTab('months');

  let listItems = [];
  const articles = siteIndex?.articles;
  if (articles?.length) {
    if (monthId) {
      listItems = articles
        .filter(article => article.month === monthId)
        .map(article => {
          if (checkDisplayArticle(article, auth)) {
            const tagItems = renderTags(article.tags);
            const articleLink = `/article/${article.id}`;
            return (<li key={article.id}><div className="article">
              <h3 className='title'><a href={articleLink}>{article.title}</a></h3>
              <div className='month'><b>{dateToDay(article.date)}</b></div>
              <blockquote dangerouslySetInnerHTML={{__html:article.preview}}></blockquote>
              <div className='tags'><ul>{tagItems}</ul></div>
            </div></li>);
          }
          return undefined;
        });
    } else {
      const monthList = collectMonthList(articles, auth);
      if (monthList?.length) {
        listItems = monthList.map(month => {
          const monthLink = `/months/${month}`;
          const count = articles.filter(a => a.month === month).length;
          return (<li key={month}><Link to={monthLink}>{month} ({count} items)</Link></li>);
        });
      }
    }
  }
  return (
    <div className="search list">
      {monthId && <div className="criteria"><Link to='/months/'><b>Month:</b> {monthId}</Link></div>}
      <ul>{listItems}</ul>
    </div>
  );
}

export default Months;