import React from "react";
import { Link, useParams } from "react-router-dom";
import { checkDisplayArticle, renderTags, dateToDay, dateToMonth, collectTagsList } from './utility';

function Tags(props) {
  const { auth, context, setTab } = props;
  const { siteIndex } = context;
  const { tagId } = useParams();

  if (setTab && typeof setTab === 'function') setTab('tags');

  let listItems = [];
  const articles = siteIndex?.articles;
  if (articles?.length) {
    if (tagId) {
      listItems = articles
        .filter(article => article.tags.indexOf(tagId) > -1)
        .map(article => {
          const shouldDisplay = checkDisplayArticle(article, auth);
          console.log('tags | shouldDisplay:', shouldDisplay, 'article:', article);
          if (shouldDisplay) {
            const articleMonth = dateToMonth(article.date);
            const articleDay = dateToDay(article.date);
            const tagItems = renderTags(article.tags, tagId);
            const articleLink = `/article/${article.id}`;
            const monthLink = `/months/${articleMonth}`;
            return (<li key={article.id}><div className="article">
              <h3 className='title'><a href={articleLink}>{article.title}</a></h3>
              <div className='month'><a href={monthLink}>{articleDay}</a></div>
              <blockquote dangerouslySetInnerHTML={{__html:article.preview}}></blockquote>
              <div className='tags'><ul>{tagItems}</ul></div>
            </div></li>);
          }
          return undefined;
        });
    } else {
      const tagList = collectTagsList(articles, auth);
      if (tagList?.length) {
        listItems = tagList.map(tag => {
          const tagLink = `/tags/${tag}`;
          const count = articles.filter(a => a.tags.indexOf(tag) > -1).length;
          return (<li key={tag}><Link to={tagLink}>{tag} ({count} items)</Link></li>);
        })
      }
    }
  }
  return (
    <div className="search list">
      {tagId && <div className='criteria'><b>Tag:</b> {tagId}</div>}
      <ul>{listItems}</ul>
    </div>
  );
}

export default Tags;