import React from "react";
import { useNavigate } from "react-router-dom";
import { checkCanEdit, getPersistentState, checkDomain } from './utility';

function Login(props) {
  const { test, auth, setAuth, getContext } = props;
  const navigate = useNavigate();

  let { siteIndex } = getContext();
  // const persistentState = getPersistentState();
  // if (persistentState) {
  //   siteIndex = persistentState?.context?.siteIndex;
  // }

  const eligible = checkDomain(siteIndex);

  console.log('login | eligibility:', eligible, auth);

  const homeUri = test ? '/test/' : '/';

  if (!eligible) {
    // not eligible; return home
    navigate(homeUri, {replace: true});
  } else if (!checkCanEdit(auth)) {
    // not logged-in; request log-in
    return (<form className='login' onSubmit={(e) => {
      e.preventDefault();
      const u =  e.target.elements.username.value;
      const p =  e.target.elements.password.value;
      console.log('login | login |', u, p);
      setAuth({key:`${u}:${p}`});
      navigate(homeUri, {replace: true});
    }}>
      <div className='row'>
        <label><span>User:</span> <input type='text' name='username'></input></label>
      </div>
      <div className='row'>
        <label><span>Password:</span> <input type='password' name='password'></input></label>
      </div>
      <div className='row'>
        <button>login</button>
      </div>
    </form>);
  } else {
    // logged-in; request log-out
    return (<form onSubmit={(e) => {
      e.preventDefault();
      console.log('login | logout...');
      setAuth({key:false});
      navigate(homeUri, {replace: true});
    }}>
      <div className='row'>
        <h4>You are logged in.</h4>
      </div>
      <div className='row'>
      <button>logout</button>
      </div>
    </form>);
  }
}

export default Login;