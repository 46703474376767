import { Link } from "react-router-dom";

const localContextMaxAge = 60 * 60 * 1000;

export let shouldGetContextFromApi = false;
export let allowPersistentState = false;


export function checkDomain(data, updateShouldGetContextFromApi = false) {
  const hostname = window.location.hostname;
  const subdomain = hostname?.split('.')?.[0];
  const verdict = subdomain && data?.k && subdomain === data.k;
  if (updateShouldGetContextFromApi) {
    shouldGetContextFromApi = verdict;
  }
  return verdict;
}

// export const host = 'http://localhost:3001';
export const host = 'https://fewn7lrtqcx7r242leigoctqna0iyzpc.lambda-url.us-east-1.on.aws/'; // lambda
// export const host = 'https://yifjhgsrq2.execute-api.us-east-1.amazonaws.com/diary-api-stage'; // api

export function dateToMonth(inDate) {
  const articleDate = new Date(inDate);
  const dateSplit = articleDate.toISOString().split('-');
  const articleMonth = dateSplit[0] + '-' + dateSplit[1];
  return articleMonth;
}
export function dateToDay(inDate) {
  const articleDate = new Date(inDate);
  const dateSplit = articleDate.toISOString().split('T')[0].split('-');
  const articleDay = dateSplit[0] + '-' + dateSplit[1] + '-' + dateSplit[2];
  return articleDay;
}

export function renderTags(inTags, currentTag, auth) {
  let tagItems = [];
  let fRemoveTag, fNewTag, requestTimeout;
  const canEdit = checkCanEdit(auth);
  if (canEdit) {
    fRemoveTag = (tag) => {
      const tagIndex = inTags.indexOf(tag);
      console.log('rRemoveTag |', tag, tagIndex, inTags);
      if (tagIndex > -1) {
        inTags.splice(tagIndex, 1);
        console.log('rRemoveTag | result:', inTags);
        const tagList = document.getElementById('tagEditor');
        console.log('rRemoveTag | tagEditor:', tagList, 'children:', tagList.children);
        if (tagList.children?.[tag]) {
          tagList.removeChild(tagList.children[tag]);
          console.log('rRemoveTag | removed by name |', tagList.children);
        } else if (tagList.children.length > 1) {
          for (let i = 1; i < tagList.children.length; i++) {
            const tagItem = tagList.children[i];
            if (tagItem?.name === tag) {
              tagList.removeChild(tagItem);
              console.log('rRemoveTag | removed by index |', tagList.children);
            }
          }
        }
      }
    };
    fNewTag = (tag, input) => {
      if (requestTimeout) {
        window.clearTimeout(requestTimeout);
      }
      if (tag) {
        requestTimeout = window.setTimeout(() => {
          console.log('fNewTag |', tag, inTags);
          if (inTags.indexOf(tag) === -1) {
            inTags.push(tag);
            console.log('fNewTag | added new tag:', tag, inTags);
            const tagList = document.getElementById('tagEditor');
            const tagItem = document.createElement('li');
            tagItem.name = tag;
            const tagButton = document.createElement('button');
            tagButton.name = tag;
            tagButton.value = tag;
            tagButton.innerText = tag;
            tagButton.onclick = (e) => { e.preventDefault(); fRemoveTag(e.target.value); };
            tagItem.appendChild(tagButton);
            tagList.appendChild(tagItem);
            input.value = '';
          } else {
            console.log('fNewTag | rejected redundant tag:', tag, inTags);
          }
        }, 1000);
      }
    };
  }
  if (inTags?.length) {
    tagItems = inTags.map(
      tag => tag === currentTag
        ? <li key={tag} name={tag}><b>{tag}</b></li>
        : <li key={tag} name={tag}>
            {canEdit
              ? <button name={tag} value={tag}
                  onClick={(e) => { e.preventDefault(); fRemoveTag(e.target.value); }}
                  >{tag}</button>
              : <Link to={`/tags/${tag}`}>{tag}</Link>
            }
          </li>
    );
  }

  
  if (canEdit) {
    // <input type='text' placeholder="add new"
    //   onChange={(e) => fNewTag(e.target.value, e.target)}
    //   />
    tagItems.unshift(
      <li key='addNew' className='addNew'>
        <input type='text' placeholder="add new" id="newTagTxt"/>
        <button id="newTagBtn"
          onClick={(e) => {
            e.preventDefault();
            const ele = document.getElementById('newTagTxt');
            if (ele) {
              fNewTag(ele.value, ele);
            }
          }}
          >add</button>
      </li>
    );
  }
  return tagItems;
}

export function checkDisplayArticle(article, auth) {
  if (article?.date && (article.isVisible || checkCanEdit(auth))) {
    return true;
  }
  return false;
}

export function collectTagsList(articles, auth) {
  const tagDictionary = {};
  if (articles?.length) {
    for (const article of articles) {
      if (article.tags?.length && checkDisplayArticle(article, auth)) {
        for (const tag of article.tags) {
          tagDictionary[tag] = (tagDictionary[tag] || 0) + 1;
        }
      }
    }
    console.log('collectTagsList |', tagDictionary);
  }
  return Object.keys(tagDictionary);
}

export function collectMonthList(articles, auth) {
  const monthDictionary = {};
  if (articles?.length) {
    for (const article of articles) {
      if (checkDisplayArticle(article, auth)) {
        const month = dateToMonth(article.date);
        monthDictionary[month] = (monthDictionary[month] || 0) + 1;
      }
    }
  }
  return Object.keys(monthDictionary);
}

export function checkCanEdit(auth) {
  return Boolean(auth?.key);
}


export function filterOutSiteIndex(state) {
  return {
    ...state,
    context: {
      ...state.context,
      siteIndex: undefined,
    }
  };
}


export function getPersistentState() {
  // const lastUpdate = window.localStorage.getItem('lastUpdate');
  let appState = window.localStorage.getItem('AppState');
  // if (appState?.length && (!lastUpdate || ((Date.now() - lastUpdate) < localContextMaxAge))) {
  if (appState?.length) {
    appState = JSON.parse(appState);
    if (appState && !allowPersistentState) {
      appState = {
        auth: appState.auth,
        context: { siteIndex: null, fullArticles: {}},
      };
    }
  } else {
    appState = undefined;
  }
  return appState;
}

export function setPersistentState(state) {
  window.localStorage.setItem('lastUpdate', Date.now());
  console.log('setPersistentState |', window.localStorage.getItem('lastUpdate'));
  // const storedState = JSON.stringify(state);
  const storedState = JSON.stringify(allowPersistentState ? state : {
    auth: state.auth,
    context: { siteIndex: null, fullArticles: {}},
  });
  window.localStorage.setItem('AppState', storedState);
}

export function clearPersistentState() {
  window.localStorage.removeItem('AppState');
}


function generateKey(
  length = 16,
  dict = '23456789acefghjkmnrstuvwxyzABCDEFGHJKMNPQRSTUVWXYZ'
) {
  const output = [];
  for (let i = 0; i < length; i++){
      const n = Math.floor((dict.length - 1) * Math.random());
      output.push(dict.substring(n,n+1));
  }
  return output.join('');
}
