import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { dateToDay, dateToMonth, renderTags, checkCanEdit, host } from './utility';

function ArticleEdit(props) {
  const { test, auth, getContext, setContext } = props;
  const context = getContext();
  const { siteIndex, fullArticles } = context;
  let { articleId } = useParams();
  const navigate = useNavigate();
  // console.log('articleEdit |', { articleId, auth });
  if (!checkCanEdit(auth)) {
    return 'access denied';
  }

  let articleDate = new Date();
  let articleTags = [];
  let articleTitle = '';
  let articlePreview = '';
  let articleContent = '';
  let articleIsVisible = false;

  // console.log(
  //   'articleEdit | looking up articleId:', articleId,
  //   siteIndex?.articles?.length,
  //   fullArticles ? Object.keys(fullArticles) : undefined,
  // );

  let article = articleId ? fullArticles[articleId] : undefined;
  if (!article) {
    // article = articleId ? require(`./data/${articleId}.json`) : undefined;
    if (!article) {
      //
      // ISSUE: article.id is changing after submit?
      //
      articleId = articleId || articleDate.getTime();
      console.log('articleEdit | articleId:', articleId);
      article = {
        id: articleId,
        title: articleTitle,
        date: articleDate.toISOString(),
        tags: articleTags,
        content: articleContent,
        isVisible: articleIsVisible,
      };
    } else {
      console.log('articleEdit | loaded article from file | article:', article);
    }
    fullArticles[articleId] = article;
  } else {
    console.log('articleEdit | article already in memory | article:', article);
    articleIsVisible = article.isVisible;
    articlePreview = article.preview;
    article = { ...article };
  }

  let listRecIsNew = false;
  const articles = siteIndex.articles;
  let listRec = articles.find(a => Number(a.id) === Number(articleId));
  if (!listRec) {
    listRec = {
      id: article.id,
      title: article.title,
      date: article.date,
      tags: article.tags,
      preview: articlePreview,
      isVisible: articleIsVisible,
    };
    listRecIsNew = true;
    // siteIndex.articles.push(listRec);
    console.log('articleEdit | new listRec:', listRec);
  } else {
    listRec = { ...listRec };
    console.log('articleEdit | existing listRec:', listRec);
  }
  
  if (articleId && articles?.length) {
    //
    // edit article form
    //
    if (article && listRec) {
      articleTitle = article.title || listRec.title || '';
      articleContent = article.content;
      articlePreview = listRec.preview;
      articleTags = [...article.tags];
      articleDate = new Date(article.date);
    }
  }

  let articleMonth = dateToMonth(article.date);
  article.month = listRec.month = articleMonth;

  return (
    <form className='articleEdit' onSubmit={(e) => {
      //
      // TODO: assemble values to send to server.
      //
      e.preventDefault();

      setTimeout(() => {
        // articleIsVisible = e.target.elements.isVisible.checked;
        if (e.target.elements.title.value) {
          articleTitle = e.target.elements.title.value;
        }
        if (e.target.elements.date.value) {
          articleDate = new Date(e.target.elements.date.value);
        }
        console.log('articleEdit onSubmit | 0 |', { articleTitle, articleDate, articlePreview, articleContent }, e);
  
        article.title = articleTitle;
        article.month = dateToMonth(article.date);
        article.tags = articleTags;
        article.content = articleContent;
        article.isVisible = articleIsVisible;
        fullArticles[article.id] = article;
  
        listRec.title = article.title;
        listRec.date = article.date;
        listRec.month = article.month;
        listRec.tags = articleTags;
        listRec.preview = articlePreview;
        listRec.isVisible = articleIsVisible;
        if (listRecIsNew) {
          articles.push(listRec);
        }
  
        const listIndex = articles.findIndex(a => a.id === listRec.id);
        articles[listIndex] = listRec;
        console.log('articleEdit onSubmit | 1 |', {
          [`fullArticles[${article.id}]`]: fullArticles[article.id],
          [`articles[${listIndex+1}/${articles.length}]`]: articles[listIndex],
        });
  
        fetch(host, {
          method: 'POST',
          headers: {
            Accepts: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            action: test ? 'put-test-article' : 'put-article',
            token: auth?.key ? 'Basic ' + Buffer.from(auth.key).toString('base64') : undefined,
            id: article.id,
            body: { article, listRec },
          })
        })
        .then(response => {
          console.log('articleEdit | put | response:', response);
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          console.log('articleEdit | put | response.data:', data);
          const { successful } = data;
          console.log('articleEdit | pushed article to server | successful:', successful);
          if (successful) {
            setContext(context);
            setTimeout(() => navigate(`/article/${article.id}`), 100);
          } else {
            const message = 'put request not successful.';
            console.error(message);
            alert(message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      }, 100);
      
    }}>
      <div className='row'>
        <label><span>Visible:</span>
          <input type='checkbox' name='isVisible'
            defaultChecked={articleIsVisible}
            onChange={(e) => articleIsVisible = e.target.checked}
            />
        </label>
      </div>
      <div className='row'>
        <label><span>Title:</span>
          <input type='text' name='title'
            defaultValue={articleTitle}
            ></input>
        </label>
      </div>
      <div className='row'>
        <label><span>Date:</span>
          <input type='text' name='date'
            defaultValue={dateToDay(articleDate)}
            ></input>
        </label>
      </div>
      <div className='row'>
        <label><span className='tags'>Tags:</span>
          <ul className='tags' name='tags' id='tagEditor'>
            {renderTags(articleTags, undefined, auth)}
          </ul>
        </label>
      </div>
      <div className='row editor'>
        <label><span>Content:</span>
          <ReactQuill name='content'
            defaultValue={articleContent}
            onChange={(value) => articlePreview = articleContent = value}
            />
          </label>
      </div>
      <div className='row'>
        <input type="submit" />
      </div>
    </form>
  )
}

export default ArticleEdit;