import React from "react";
// import siteIndex from "./data/index.json";
import { useState, useEffect } from 'react';
// import { Link } from "react-router-dom";
import { dateToDay, dateToMonth, renderTags, checkDisplayArticle } from './utility';

function Home(props) {
  const { test, auth, getContext, setTab } = props;
  let { siteIndex } = getContext(test) ?? {};

  if (setTab && typeof setTab === 'function') setTab('home');

  let timeout;
  let timeoutTries = 0;
  console.log('home | auth:', auth, '| articles:', siteIndex?.articles?.length);
  const timeoutFn = () => {
    clearTimeout(timeout);
    siteIndex = getContext()?.siteIndex;
    console.log('home | timeoutTries:', timeoutTries, '| articles:', siteIndex?.articles?.length);
    if (!siteIndex?.articles?.length && ++timeoutTries < 9) {
      timeout = setTimeout(timeoutFn, 66 * timeoutTries);
    } else {
      refreshItemsList();
    }
  }
  timeout = setTimeout(timeoutFn, 100);

  let itemsRendered = 0;

  function renderListItem(article, test = false) {
    itemsRendered++;
    const articleMonth = dateToMonth(article.date);
    const articleDay = dateToDay(article.date);
    const tagItems = renderTags(article.tags);
    const articleLink = `/article/${test ? 'test/' : ''}${article.id}`;
    const monthLink = `/months/${articleMonth}`;
    const classNames = ['article'];
    if (!article.isVisible) {
      classNames.push('hidden');
    }
    return (<li key={article.id}><div className={classNames.join(' ')}>
      <h3 className='title'><a href={articleLink}>{article.title}</a></h3>
      <div className='month'><a href={monthLink}>{articleDay}</a></div>
      <blockquote dangerouslySetInnerHTML={{__html:article.preview}}></blockquote>
      <div className='tags'><ul>{tagItems}</ul></div>
    </div></li>);
  }

  const [listItems, setListItems] = useState([]);

  const refreshItemsList = () => {
    const allArticles = siteIndex?.articles || [];
    const visibleArticles = allArticles?.filter(a => checkDisplayArticle(a, auth)) ?? [];
    /*console.log(
      'home | refreshItemsList | listItems:', listItems?.length,
      'allArticles:', allArticles?.length,
      'visibleArticles:', visibleArticles?.length,
    );*/
    if (
      visibleArticles?.length &&
      visibleArticles.length !== listItems.length
    ) {
      setListItems(
        visibleArticles.map(a => renderListItem(a, test))
      );
    }
  }

  useEffect(() => {
    refreshItemsList();
  }, [refreshItemsList, siteIndex, listItems, setListItems, auth]);


  let checkItemsTimeout;
  function checkItems() {
    clearTimeout(checkItemsTimeout);
    ({ siteIndex } = getContext(test) ?? {});
    const articleCount = siteIndex?.articles?.length || 0;
    /*console.log(
      'home | checkItems | articleCount:', articleCount,
      'itemsRendered:', itemsRendered,
      'timeoutPromptedReload:', window.localStorage.getItem('timeoutPromptedReload'),
    );*/

    // if (!articleCount) {
    //   if (!window.localStorage.getItem('timeoutPromptedReload')) {
    //     window.localStorage.setItem('timeoutPromptedReload', '1');
    //     checkItemsTimeout = window.setTimeout(checkItems, 3000);
    //   }
    // } else {
    //   window.localStorage.removeItem('timeoutPromptedReload');
    //   refreshItemsList();
    // }

    refreshItemsList();
  }

  refreshItemsList();
  if (!itemsRendered) {
    checkItems();
  }



  // let timeouts = 0;
  // let itemsTimeout;

  // function checkItems() {
  //   clearTimeout(itemsTimeout);
  //   const allArticles = siteIndex?.articles?.length || 0;
  //   const visibleArticles = siteIndex?.articles?.filter(a => checkDisplayArticle(a, auth)) ?? [];
  //   console.log(
  //     'home | checkItems | listItems:', listItems?.length,
  //     'itemsRendered:', itemsRendered,
  //     'allArticles:', allArticles?.length,
  //     'visibleArticles:', visibleArticles?.length,
  //     'timeouts:', timeouts,
  //     'timeoutPromptedReload:', window.localStorage.getItem('timeoutPromptedReload'),
  //     'stack:', (new Error()).stack,
  //   );
  //   if (
  //     visibleArticles?.length &&
  //     !itemsRendered &&
  //     !listItems?.length &&
  //     visibleArticles.length !== listItems.length
  //   ) {
  //     setListItems(
  //       visibleArticles.map(a => renderListItem(a))
  //     );
  //     window.localStorage.removeItem('timeoutPromptedReload');
  //   } else if (!allArticles?.length) {
  //     timeouts++;
  //     if (timeouts < 3) {
  //       itemsTimeout = window.setTimeout(checkItems, 3000);
  //     } else if (!window.localStorage.getItem('timeoutPromptedReload')) {
  //       window.localStorage.setItem('timeoutPromptedReload', '1');
  //       window.location.reload();
  //     }
  //   }
  // }

  // checkItems();

  return (
    <div className="home list">
      {listItems?.length ? <ul>{listItems}</ul> : <ul>...</ul>}
    </div>
  );
}

export default Home;