import React, {useState} from "react";
import { Link, useParams } from "react-router-dom";
import { shouldGetContextFromApi, dateToDay, dateToMonth, renderTags, checkDisplayArticle, checkCanEdit, host, getPersistentState } from './utility';

function Article(props) {
  const { auth, getContext, setContext } = props;
  const context = getContext();
  const { fullArticles } = context;
  const { articleId } = useParams();

  const [ article, setArticle ] = useState(fullArticles[articleId]);

  // useEffect(() => {
    if (!article || !Object.keys(article).length) {
      if (shouldGetContextFromApi) {
        // console.log('getArticleFromServer | auth:', auth);
        fetch(host, {
          method: 'POST',
          headers: {
            Accepts: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            action: 'get-article',
            token: auth?.key ? 'Basic ' + Buffer.from(auth.key).toString('base64') : undefined,
            id: articleId,
          })
        })
        .then(response => {
          // console.log('getArticleFromServer | 1 | response:', response);
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          // console.log('getArticleFromServer | 1 | data:', data);
          setArticle(data);
          fullArticles[articleId] = data;
          context.fullArticles = fullArticles;
          // console.log(`getArticleFromServer | 1 | fullArticles[${articleId}]:`, fullArticles[articleId]);
          setContext(context);
          // console.log('getArticleFromServer | 1 | persistentState:', getPersistentState());
          // renderArticle(article);
        })
        .catch((error) => {
          console.error(error);
        });
      } else {
        console.log('getArticleFromServer | path...');
        // import(`./data/${articleId}.json`
        fetch(
          `../data/${articleId}.json`,
          {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
          },
        ).then(response => {
          // console.log('getArticleFromServer | 2 | response:', response);
          if (response.ok) {
            return response.json();
          }
        }).then(data => {
          if (data) {
            setArticle(data);
            fullArticles[articleId] = data;
            context.fullArticles = fullArticles;
            // console.log(`getArticleFromServer | 2 | fullArticles[${articleId}]:`, fullArticles[articleId]);
            setContext(context);
            // console.log('getArticleFromServer | 2 | persistentState:', getPersistentState());
          } else {
            console.log('article | getting article | failed to load from path.');
          }
        });
      }
    } else {
      // console.log(`article | article already in memory | articleId:${articleId} article:`, article);
    }
  // }, [article, setArticle]);

  const makeArticleTitle = (article, auth) => {
    return checkDisplayArticle(article, auth)
    ? (<h3>
        <span>{article.title}</span>
        {checkCanEdit(auth) && <span className='edit'><Link to={`/edit/${articleId}`}>edit</Link></span>}
      </h3>)
    : (!article ? <span>{articleId} not found...</span> : <span>access denied</span>);
  }

  return (
    <div className="article">
      <div>
          {makeArticleTitle(article, auth)}
          {checkDisplayArticle(article, auth) && (
            <div className='articleBody'>
              <div className='month'><a href={`/months/${dateToMonth(article.date)}`}>{dateToDay(article.date)}</a></div>
              <blockquote dangerouslySetInnerHTML={{__html:article.content}}></blockquote>
              <div className='tags'><ul>{renderTags(article.tags)}</ul></div>
            </div>
          )}
        </div>
    </div>
  );
}

export default Article;